import React from "react";

function VectorIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="324"
            height="152"
            fill="none"
            viewBox="0 0 324 152"
        >
            <path
                stroke="#8BA3E4"
                strokeDasharray="11 11"
                strokeWidth="2"
                d="M1 151c14-26 78-83 170-78 89.368 4.857 144-48 152-72"
            ></path>
        </svg>
    );
}

export default VectorIcon;
