import React from "react";
import { IImage } from "./Card";
import styles from "./styles.module.scss";

interface IBlogCardProps {
    title: string;
    url?: string;
    image?: IImage;
}

const BlogCard = ({ title, url, image }: IBlogCardProps) => {
    return (
        <div
            className={`${styles.blogCardContainer} flex flex-col-reverse lg:flex-col text-left`}
        >
            <div className="bg-white p-6 lg:p-10 max-w-[400px] flex flex-col justify-center gap-4 rounded-b-10 lg:rounded-t-10 lg:rounded-b-none">
                <div className="text-lg lg:text-2xl font-normal">{title}</div>
                <a
                    href={url}
                    className="flex items-center gap-2 text-[#4188F2] hover:underline w-fit"
                >
                    <span>Read More</span>
                    <span></span>
                </a>
            </div>
            <div className="relative w-[100%] max-w-[400px] ">
                <img
                    src={image?.fullPath}
                    alt=""
                    className="rounded-t-10 lg:rounded-b-10 lg:rounded-t-none"
                />
            </div>
        </div>
    );
};

export default BlogCard;
