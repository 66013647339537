import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import AutoWarrantyWrapper from "@components/layouts/autoWarrantyWrapper";
import FirstSection from "@components/autowarranty/homePage/FirstSection";
import BgImageContentSection from "@components/autowarranty/homePage/BgImageContentSection";
import SeventhSection from "@components/autowarranty/homePage/SeventhSection";
import ThirdSection from "@components/autowarranty/homePage/ThirdSection";
import EighthSection from "@components/autowarranty/homePage/EighthSection";
import FifthSection from "@components/autowarranty/homePage/FifthSection";
import Faq from "@components/autowarranty/homePage/Faq";

export default function AutoWarrantyHome(): ReactElement {
    return (
        <AutoWarrantyWrapper>
            <FirstSection />
            <BgImageContentSection
                id="why-auto-warranty-com"
                title="How to Protect Your Car With an Extended Warranty"
                content="Your car is a really important and potentially expensive
                    investment. You'll want to ensure it stays in great
                    condition for as long as possible. One way to do that is to
                    protect it by getting an extended auto warranty. But what
                    exactly is an extended car warranty? We will explore
                    everything you need to know about these warranties to help
                    you make an informed decision about whether or not they're
                    right for you. We'll go over the basics, including what
                    vehicle parts and repairs may be covered and how those
                    differ from a manufacturer's warranty coverage. We'll give
                    you some tips on how to choose the right policy, and we'll
                    also discuss potential downsides you may encounter. We hope
                    to help you make a well-rounded decision that works best for
                    your budget and needs."
                className="second-section"
            />
            <ThirdSection />
            <BgImageContentSection
                title="The Basics of Extended Car Warranties"
                content="Extended car warranties act like insurance for your car's major components. Of course, unlike your car insurance, your warranty will not cover accidental damage, among other exclusions. Generally, they cover repairs for specific mechanical breakdowns and come with a deductible, which is the amount the owner must pay out of pocket for each qualified repair.
                        In addition to covering repairs, many car warranties also include benefits like roadside assistance for added convenience. However, it's important to note that they usually do not cover routine maintenance. As a car owner, you can purchase an extended warranty upfront or add it later, depending on your preferences and needs. If you take the time to understand what different types of service contracts can do for you, you'll be more empowered to decide what option to choose and when to protect your vehicle."
                className="fourth-section"
                direction="right"
            />
            <FifthSection />
            <SeventhSection />
            <EighthSection />
            <BgImageContentSection
                title="Making the Best Coverage Decision for Yourself"
                content="Extended car warranties can provide peace of mind and financial security while helping you protect your vehicle. Whether you choose a manufacturer's extended plan or one from a third-party provider, it's important to carefully consider the coverage options, terms, and costs involved. You should take into account factors such as the age and mileage of your car, as well as your budget and individual needs, when looking for appropriate coverage for your car.
                Of course, while it can save you from unexpected repair expenses, some plans may not be necessary or cost-effective for your specific requirements. Conduct thorough research, read customer reviews, and consult with experts to make an informed decision. You should take your time, thoroughly weigh the pros and cons of any warranty provider you consider, and make an informed purchase decision about what type of coverage and which provider is right for you. We think that when you consider the potential savings and benefits an extended vehicle warranty contract offers, you will see a lot of value in getting one for your car. The right choice is out there for you!"
                className="eighth-section"
            />
            <Faq />
        </AutoWarrantyWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "autoWarranty");
};
