import React from "react";
import ResearchIcon from "../icons/ResearchIcon";
import UnderstandCoverageIcon from "../icons/UnderstandCoverageIcon";
import ConsiderHabitsIcon from "../icons/ConsiderHabitsIcon";
import CheckForFlexibilityIcon from "../icons/CheckForFlexibilityIcon";
import CompareCostsIcon from "../icons/CompareCostsIcon";
import ReadFinePrintsIcon from "../icons/ReadFinePrintsIcon";
import Card from "../shared/card/Card";

const SeventhSection = () => {
    const cards = [
        {
            icon: <ResearchIcon />,
            title: "Research Providers: ",
            content:
                "Start by looking for known, reputable providers online. You'll want companies with a strong track record, positive customer reviews, and industry accreditation, among other things. Sites like BBB and Trustpilot will help you with this task.",
        },
        {
            icon: <UnderstandCoverageIcon />,
            title: "Understand Coverage: ",
            content:
                "Review the coverage details of specific plans carefully, noting included components, systems, and any exclusions or limitations, often listed on providers’ websites or available in sample policy contracts online.",
        },
        {
            icon: <ConsiderHabitsIcon />,
            title: "Consider Your Driving Habits: ",
            content:
                "Your driving habits and the type of vehicle you own should influence your choice of coverage. If you have a long commute or drive frequently, comprehensive coverage might be more suitable.",
        },
        {
            icon: <CheckForFlexibilityIcon />,
            title: "Check for Flexibility: ",
            content:
                "Look for warranties that allow you to choose a repair facility, whether it's the dealership or an independent mechanic. Flexibility can be key, especially if you have a preferred repair shop.",
        },
        {
            icon: <CompareCostsIcon />,
            title: "Compare Costs: ",
            content:
                "Compare quotes from different providers to ensure you're getting a competitive price. Be cautious of warranties that seem too cheap, as they may lack necessary coverage.",
        },
        {
            icon: <ReadFinePrintsIcon />,
            title: "Read the Fine Print: ",
            content:
                "When you receive a warranty quote, you should also get a copy of the contract. Carefully read its terms and conditions, paying special attention to the deductible amount, claims procedures, exclusions.",
        },
    ];
    return (
        <section className="flex flex-col items-center">
            <div className="left-0 w-[100vw] bg-[#F3F6FF] flex flex-col items-center justify-center gap-16 py-[5rem] lg:py-[15rem] px-6">
                <div className="flex flex-col items-center max-w-6xl text-center gap-4">
                    <h2 className="text-2xl lg:text-4xl font-light text-[#21409A]">
                        What to Research When Deciding on a VSC
                    </h2>
                    <p className="text-sm sm:text-xl font-light">
                        When considering a service contract for your car, making
                        an informed decision is crucial. Here are a few
                        important factors that can help you make the best
                        decision:
                    </p>
                </div>
                <div className="flex flex-col lg:flex-row gap-6 justify-center flex-wrap max-w-7xl">
                    {cards.map((card) => (
                        <Card
                            key={card.title}
                            type="info"
                            title={card.title}
                            text={card.content}
                            icon={card.icon}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default SeventhSection;
