const CaretDownIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path
                fill="#1B52F1"
                fillRule="evenodd"
                d="M6.293 8.793a1 1 0 011.414 0L12 13.086l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
};

export default CaretDownIcon;
