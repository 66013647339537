import React from "react";
import styles from "./styles.module.scss";

export interface IContentWithImage {
    image: string;
    title?: string;
    content: string;
    direction?: "left" | "right";
    imageStyle?: string;
}

const ContentWithImage = ({
    image,
    title,
    content,
    direction = "left",
    imageStyle = "",
}: IContentWithImage) => {
    return (
        <div
            className={`flex justify-around gap-12 items-center ${
                styles[`${direction}`]
            }`}
        >
            {title ? (
                <span className="text-[#21409A] text-2xl lg:text-4xl max-w-xl block sm:hidden">
                    {title}
                </span>
            ) : (
                <></>
            )}
            <div>
                <img src={image} alt="blue-car" className={`${imageStyle}`} />
            </div>
            <div className="flex flex-col gap-8 font-light">
                {title ? (
                    <span className="text-[#21409A] text-xl lg:text-4xl max-w-xl hidden sm:block">
                        {title}
                    </span>
                ) : (
                    <></>
                )}
                <p className={`max-w-xl text-sm sm:text-base`}>{content}</p>
            </div>
        </div>
    );
};

export default ContentWithImage;
