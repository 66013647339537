import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

interface IInfoCardProps {
    icon?: ReactElement;
    title: string;
    text?: string;
}

const InfoCard = ({ icon, title, text }: IInfoCardProps) => {
    return (
        <div
            className={`${styles.infoCardContainer} flex flex-col gap-4 justify-center rounded-xl p-8 w-full md:min-h-[370px] lg:min-h-[340px] lg:min-w-[400px] max-w-[400px] bg-white text-base`}
        >
            <span>{icon}</span>
            <div>
                <span className="font-bold ">{title}</span>
                <span className="max-w-[270px] text-sm sm:text-base">
                    {text}
                </span>
            </div>
        </div>
    );
};

export default InfoCard;
