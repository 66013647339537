import React, { useState } from "react";
import powerTrain from "@public/autowarranty/assets/images/powerTrain.png";
import powertrainPlus from "@public/autowarranty/assets/images/powertrainPlus.png";
import comprehensive from "@public/autowarranty/assets/images/comprehensive.png";
import exclusionary from "@public/autowarranty/assets/images/exclusionary.png";
import wrapAroundCoverage from "@public/autowarranty/assets/images/wrap-aroundCoverage.png";
import specializedCoverage from "@public/autowarranty/assets/images/specializedCoverage.png";
import CaretDownIcon from "../icons/CaretDownIcon";
import AnimateHeight from "@components/shared/animateHeight";
import styles from "./styles.module.scss";

interface ICoverage {
    title: string;
    content: string;
    image: string;
}

const CoverageTypes = () => {
    const coverages: ICoverage[] = [
        {
            title: "Powertrain",
            content:
                "This is usually your basic warranty coverage that covers the engine and drivetrain parts like the transmission, drive axles, and transfer unit. This is the lowest layer of protection that comes with your manufacturer's warranty and the last to expire.",
            image: powerTrain.src,
        },
        {
            title: "Powertrain plus",
            content:
                "This intermediate plan expands on powertrain protection by adding other parts that differ by provider. These could include the AC, steering, brakes, and some base electrical components.",
            image: powertrainPlus.src,
        },
        {
            title: "Comprehensive",
            content:
                "These plans typically cover the highest number of stated components but may also have a lot of exclusions. They might cover your fuel system, cooling system, suspension, anti-lock brakes, and high-tech electronics, among many possible categories.",
            image: comprehensive.src,
        },
        {
            title: "Exclusionary",
            content:
                "This type of plan is the highest tier of coverage and is meant to cover all breakdown repairs except those that the contract explicitly excludes. This is the closest thing to your manufacturer's bumper-to-bumper warranty, which brand-new cars come with for a limited time.",
            image: exclusionary.src,
        },
        {
            title: "Wrap-around coverage",
            content:
                "Providers might offer comprehensive plans that cover everything but the powertrain components, designed for newer cars with still valid powertrain factory warranties.",
            image: wrapAroundCoverage.src,
        },
        {
            title: "Specialized coverage",
            content:
                "Some providers will offer special plans for hybrid or electric cars or for different vehicle types like motorcycles or 4x4s. You might also find these vehicle types or parts covered as add-on packages, as you might for luxury electronics, emissions, or commercial-use cars.",
            image: specializedCoverage.src,
        },
    ];
    const [selectedItem, setSelectedItem] = useState(coverages[0]);

    return (
        <div className="flex flex-col-reverse sm:flex-row items-center justify-around w-full my-20">
            <div className="max-w-[500px] hidden sm:block">
                <img src={selectedItem.image} alt={selectedItem.title} />
            </div>
            <div className="flex flex-col gap-2 max-w-[377px]">
                {coverages.map((item) => (
                    <div
                        className="border border-[#4188F2] rounded-10 p-4 flex flex-col cursor-pointer"
                        key={item.title}
                        onClick={() => setSelectedItem(item)}
                    >
                        <div
                            className={`flex items-center justify-between font-bold text-sm sm:text-lg ${
                                selectedItem.title === item.title
                                    ? styles["divider"]
                                    : ""
                            }`}
                        >
                            <span>{item.title}</span>
                            <div
                                className={`w-[30px] h-[30px] ${
                                    selectedItem.title === item.title
                                        ? `${styles["rotate-icon"]}`
                                        : ""
                                }`}
                            >
                                <CaretDownIcon />
                            </div>
                        </div>
                        <AnimateHeight
                            duration={500}
                            className="max-w-[377px] font-base text-xs sm:text-sm"
                            height={
                                selectedItem.title === item.title ? "auto" : 0
                            }
                        >
                            <div>{item.content}</div>
                            <div className="block sm:hidden max-w-[500px]">
                                <img
                                    src={selectedItem.image}
                                    alt={selectedItem.title}
                                />
                            </div>
                        </AnimateHeight>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CoverageTypes;
