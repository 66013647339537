import React from "react";
import BlueCar from "@public/autowarranty/assets/images/blueCar.png";

const FirstSection = () => {
    return (
        <section className="flex flex-col items-center relative -z-10">
            <div className="w-[100vw] bg-[#E5E5E5] flex flex-col items-center relative gap-10 py-12">
                <h1 className="text-[18vw] uppercase text-white font-extralight cursor-default h-0 hidden md:block">
                    warranty
                </h1>
                <div
                    className={`w-[35vw] min-w-[300px] pointer-events-none mt-0 lg:mt-[14vh]`}
                    draggable="false"
                >
                    <img src={BlueCar.src} alt="carImage" />
                </div>
                <div className="w-full max-w-7xl px-5">
                    <div className="w-full max-w-[730px]">
                        <span className="text-[#21409A] font-light text-4xl lg:text-7xl uppercase">
                            auto
                        </span>
                        <br />
                        <span className="text-black font-bold text-4xl lg:text-7xl uppercase">
                            warranty
                        </span>
                        <br />
                        <br />
                        <p className="text-sm sm:text-base font-light">
                            Auto warranties are agreements provided by the
                            manufacturer or dealer that cover repairs or
                            replacements of specific parts within a set period
                            or mileage, ensuring that any defects or issues
                            arising from normal use are addressed without cost
                            to the owner. These warranties can vary widely,
                            including basic bumper-to-bumper coverage,
                            powertrain warranties, and additional coverage
                            options like rust protection or roadside assistance.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FirstSection;
