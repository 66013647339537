import React from "react";
import blueCarFront from "@public/autowarranty/assets/images/blueCarFront.png";
import blueCarSide from "@public/autowarranty/assets/images/blueCarSide.png";
import blueCarBack from "@public/autowarranty/assets/images/blueCarBack.png";
import ContentWithImage, {
    IContentWithImage,
} from "../shared/contentWIthImage/ContentWithImage";
import VectorIcon from "../icons/VectorIcon";
import styles from "./styles.module.scss";
import useWindowSize from "@hooks/useWindowSize";

const ThirdSection = () => {
    const items: IContentWithImage[] = [
        {
            image: blueCarFront.src,
            title: "What Are Extended Car Warranties?",
            content: `An auto warranty will protect your vehicle against repairs to a variable amount of parts under its hood. They are designed to protect you from unexpected repair costs due to breakdowns of your car's mechanical or electrical parts, helping to bring you some peace on the road. A new vehicle will come off the lot already covered by a manufacturer’s warranty, sometimes called a factory warranty. This warranty typically has two layers of coverage that would cover it for a certain amount of time or driving miles. This means your car's warranty starts with a "bumper-to-bumper" protection layer, and when that expires, you are left with a much more limited powertrain warranty for the remainder of its term.`,
        },
        {
            image: blueCarSide.src,
            content: `Extended car warranties go beyond your car's original factory warranty term to provide additional coverage for your vehicle. What you are extending there is the amount of parts you're covered for and the amount of time or miles that the coverage lasts. This type of coverage may be known as a vehicle service contract (VSC) when it's sold by third-party providers that are independent of your vehicle's manufacturer, and in California, it might be sold as Mechanical Breakdown Insurance (MBI) policies. VSCs are a great idea whether you have a newer car with an expiring factory warranty or a well-used older car with many more miles on it.`,
            direction: "right",
        },
        {
            image: blueCarBack.src,
            content: `When considering one of these vehicle protection plans, be sure to review the exclusions and limitations and compare options from different providers. Really, the only way to ensure you know how much you are covered is to read the contract's fine print, which includes looking for any mileage limits or restrictions that may apply. This can be true whether you buy your warranty protection from a dealership or a warranty company over the phone because you may not think of everything you want to ask on the spot or have enough time to ask it. By familiarizing yourself with the contract details, you can get the most out of your warranty and ensure that it meets your specific needs.`,
        },
    ];

    const { width } = useWindowSize();

    return (
        <section className="h-full my-[5rem] lg:my-[10rem] flex flex-col gap-20 sm:gap-40">
            {items.map((item, idx, self) => (
                <div key={idx} className="relative">
                    <ContentWithImage
                        image={item.image}
                        content={item.content}
                        title={item.title}
                        direction={item.direction}
                    />
                    {idx !== self.length - 1 && width > 1024 && (
                        <div
                            className={`absolute right-1/3 -mt-4 ${
                                idx % 2 === 0 ? "" : styles["mirror"]
                            }`}
                        >
                            <VectorIcon />
                        </div>
                    )}
                </div>
            ))}
        </section>
    );
};

export default ThirdSection;
