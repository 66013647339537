import React from "react";

function UnderstandCoverageIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <rect
                width="55"
                height="55"
                x="0.5"
                y="0.5"
                fill="#fff"
                stroke="#537BD4"
                strokeDasharray="6 6"
                rx="4.5"
            ></rect>
            <mask
                id="mask0_8_3"
                style={{ maskType: "luminance" }}
                width="32"
                height="32"
                x="12"
                y="12"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#fff" d="M44 12H12v32h32V12z"></path>
            </mask>
            <g mask="url(#mask0_8_3)">
                <path
                    fill="#537BD4"
                    fillRule="evenodd"
                    d="M16.702 19.168l-.129-.257a.99.99 0 00-.578-.502.99.99 0 00-.763.055.964.964 0 00-.502.578.964.964 0 00.055.763l1.32 2.642a.99.99 0 00.579.502.991.991 0 00.87-.117l6-4a.99.99 0 00.427-.636.991.991 0 00-.149-.75.964.964 0 00-.636-.427.964.964 0 00-.75.149l-1.944 1.295-3.114 2.077-.686-1.372zM28 18a.963.963 0 00-.707.293A.963.963 0 0027 19c0 .276.098.512.293.707A.963.963 0 0028 20h14a.963.963 0 00.707-.293A.963.963 0 0043 19a.963.963 0 00-.293-.707A.963.963 0 0042 18H28zm-11.277 9.168l-.129-.257a.964.964 0 00-.578-.502.963.963 0 00-.763.055.99.99 0 00-.502.578.99.99 0 00.055.763l1.32 2.642a.964.964 0 00.579.502.964.964 0 00.87-.117l6-4a.964.964 0 00.427-.636.964.964 0 00-.15-.75.963.963 0 00-.635-.427.964.964 0 00-.75.149l-5.058 3.372-.686-1.372zM28 27a.963.963 0 00-.707.293A.963.963 0 0027 28c0 .276.098.512.293.707A.963.963 0 0028 29h14a.963.963 0 00.707-.293A.963.963 0 0043 28a.963.963 0 00-.293-.707A.963.963 0 0042 27H28zm-11.277 8.168l-.129-.257a.963.963 0 00-.578-.502.963.963 0 00-.763.055.99.99 0 00-.502.578.99.99 0 00.055.763l1.32 2.642a.964.964 0 00.579.502.964.964 0 00.87-.117l6-4a.99.99 0 00.427-.636.991.991 0 00-.15-.75.963.963 0 00-.635-.427.964.964 0 00-.75.149l-1.944 1.295-3.114 2.077-.686-1.372zM28 36a.963.963 0 00-.707.293A.963.963 0 0027 37c0 .276.098.512.293.707A.963.963 0 0028 38h14a.963.963 0 00.707-.293A.963.963 0 0043 37a.963.963 0 00-.293-.707A.963.963 0 0042 36H28z"
                    clipRule="evenodd"
                ></path>
            </g>
        </svg>
    );
}

export default UnderstandCoverageIcon;
