import React from "react";

function CompareCostsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <rect
                width="55"
                height="55"
                x="0.5"
                y="0.5"
                fill="#fff"
                stroke="#537BD4"
                strokeDasharray="6 6"
                rx="4.5"
            ></rect>
            <path
                fill="#537BD4"
                fillRule="evenodd"
                d="M41 15H21.49v7.49H14V42h19.51v-7.49H41V15zm-9.436 25.084H15.916V24.435h5.574V34.51h10.075v5.574zm7.52-7.52H33.51V22.49H23.436v-5.574h15.648v15.649z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CompareCostsIcon;
