import React, { useState } from "react";
import CaretDownIcon from "../icons/CaretDownIcon";
import styles from "./styles.module.scss";
import AnimateHeight from "@components/shared/animateHeight";

export interface IFaqItem {
    question: string;
    answer: string;
}

const FaqItem = ({ question, answer }: IFaqItem) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="flex flex-col gap-4 border-b py-7 last:border-b-0 w-full">
            <div
                className="flex items-center gap-4 cursor-pointer w-full"
                onClick={() => setOpen((prev) => !prev)}
            >
                <div
                    className={`w-[30px] h-[30px] ${
                        open ? `${styles["rotate-icon"]}` : ""
                    }`}
                >
                    <CaretDownIcon />
                </div>
                <span className="font-medium text-lg lg:text-2xl max-w-[70vw]">
                    {question}
                </span>
            </div>
            <AnimateHeight duration={500} height={open ? "auto" : 0}>
                <div
                    className="lg:pl-[3rem] text-base lg:text-lg font-base lg:font-medium mt-4 bg-[#F1F7FF] p-4 rounded-xl"
                    dangerouslySetInnerHTML={{ __html: answer }}
                />
            </AnimateHeight>
        </div>
    );
};

export default FaqItem;
