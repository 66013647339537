import React from "react";
import relaxed from "@public/autowarranty/assets/images/relaxed.png";
import thumbsUp from "@public/autowarranty/assets/images/thumbsUp.png";
import ContentWithImage, {
    IContentWithImage,
} from "../shared/contentWIthImage/ContentWithImage";

const EighthSection = () => {
    const items: IContentWithImage[] = [
        {
            image: relaxed.src,
            title: "How to Choose the Right Warranty Coverage",
            imageStyle: "rounded",
            content: `When selecting an extended warranty company for your car, several factors must be considered. First, take into account the age and mileage of your car. Older cars with higher mileage may be more prone to mechanical breakdowns and may require a more comprehensive warranty. Remember that many providers may not offer their highest plans to cars over a certain mileage limit, but you can find one that does if you shop around.
Next, evaluate the likelihood of needing major repairs based on the make and model of your car. Some cars have a reputation for being more reliable, while others may have common issues. If your car model may have issues with certain parts, ensure your policy will cover those parts. However, if an issue is part of a recall or is a known manufacturer's defect, you won't be able to claim its repair with a VSC, and you will have to contact your car manufacturer instead.
Also, consider the reputation and financial stability of the service contract provider because you want to ensure that they will be able to honor the contract if needed, and pay special attention to their customer service record. Furthermore, consider the value of their perks and benefits, such as rental car reimbursement or coverage for oil changes. Finally, compare the extended warranty cost to the potential cost of certain repairs. It's important to strike a balance between coverage and affordability.`,
        },
        {
            image: thumbsUp.src,
            title: "Decoding the Cost of an Extended Car Warranty",
            content: `If you're considering a policy, it helps to understand the factors that can influence the costs you pay. So many elements come into play with your quote, including the make, model, and age of your vehicle and the length and level of coverage you choose. Although VSC companies might require an upfront payment, they may offer financing or installment options.
    The quotes you get from different providers may vary, but weighing those against the potential savings their plans can provide is important. Keep in mind that, similar to health insurance, extended auto warranties will have some exclusions and rules that might seem strict. Still, they can offer valuable protection against costly repairs down the line. That being said, it's a good idea to consider your specific needs and budget before making a decision.
    To ensure you're getting the best value for your money, it's important to compare different warranty providers and carefully read their contracts, including the fine print. This will help you understand exactly what's covered and what isn't. While their costs may seem high, they can be worth it in the long run—especially when you factor in the potential cost of repairs you may face.`,
            direction: "right",
            imageStyle: "rounded",
        },
    ];

    return (
        <section className="h-full my-[5rem] lg:my-[10rem] flex flex-col gap-20 sm:gap-40">
            {items.map((item, idx) => (
                <div key={idx} className="relative">
                    <ContentWithImage
                        image={item.image}
                        content={item.content}
                        title={item.title}
                        direction={item.direction}
                        imageStyle={item.imageStyle}
                    />
                </div>
            ))}
        </section>
    );
};

export default EighthSection;
