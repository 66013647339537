import React from "react";

function ReadFinePrintsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <rect
                width="55"
                height="55"
                x="0.5"
                y="0.5"
                fill="#fff"
                stroke="#537BD4"
                strokeDasharray="6 6"
                rx="4.5"
            ></rect>
            <path
                fill="#537BD4"
                d="M24.874 20.855l-6.781-1.81a.968.968 0 10-.498 1.87l6.78 1.81a.965.965 0 001.186-.686.963.963 0 00-.687-1.184zM24.874 25.698l-6.781-1.81a.968.968 0 10-.498 1.871l6.78 1.81a.974.974 0 001.185-.686.963.963 0 00-.686-1.185zM24.874 30.542l-6.781-1.81a.968.968 0 10-.498 1.871l6.78 1.81a.965.965 0 001.186-.686.963.963 0 00-.687-1.185zM24.874 35.386l-6.781-1.81a.968.968 0 10-.498 1.87l6.78 1.81a.974.974 0 001.185-.686.963.963 0 00-.686-1.184zM32.375 22.759a.965.965 0 00.25-.033l6.78-1.81a.968.968 0 10-.498-1.871l-6.781 1.81a.969.969 0 00.25 1.904zM32.375 27.603a.96.96 0 00.25-.034l6.78-1.81a.968.968 0 10-.498-1.871l-6.781 1.81a.969.969 0 00.25 1.904zM32.375 32.446a.965.965 0 00.25-.033l6.78-1.81a.968.968 0 10-.498-1.871l-6.781 1.81a.969.969 0 00.25 1.904zM32.375 37.29a.96.96 0 00.25-.033l6.78-1.81a.968.968 0 10-.498-1.871l-6.781 1.81a.969.969 0 00.25 1.904z"
            ></path>
            <path
                fill="#537BD4"
                d="M43.624 13.199a.972.972 0 00-.842-.166L28.5 16.842l-14.282-3.81a.972.972 0 00-.842.167.973.973 0 00-.376.77v25.187c0 .437.293.825.72.936l14.53 3.875a.966.966 0 00.5 0l14.53-3.875a.965.965 0 00.72-.936V13.968a.973.973 0 00-.376-.769zm-28.687 2.032l12.594 3.36v23.183l-12.593-3.36V15.231zm27.126 23.183l-12.594 3.36V18.591l12.594-3.36v23.183z"
            ></path>
        </svg>
    );
}

export default ReadFinePrintsIcon;
