import React from "react";

function ConsiderHabitsIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <rect
                width="55"
                height="55"
                x="0.5"
                y="0.5"
                fill="#fff"
                stroke="#537BD4"
                strokeDasharray="6 6"
                rx="4.5"
            ></rect>
            <path
                fill="#537BD4"
                fillRule="evenodd"
                d="M43 28.184c0-8.12-6.607-14.702-14.749-14.702-8.147 0-14.751 6.582-14.751 14.702 0 8.118 6.604 14.704 14.751 14.704C36.393 42.887 43 36.302 43 28.184zm-2.64 0c0-6.665-5.422-12.067-12.109-12.067-6.69 0-12.111 5.402-12.111 12.067 0 6.667 5.421 12.071 12.111 12.071 6.687 0 12.11-5.404 12.11-12.071z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#537BD4"
                fillRule="evenodd"
                d="M31.544 28.183c0-1.81-1.476-3.28-3.293-3.28a3.289 3.289 0 00-3.295 3.28 3.29 3.29 0 003.295 3.285 3.29 3.29 0 003.293-3.285z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#537BD4"
                fillRule="evenodd"
                d="M26.622 13.633h3.255v12.104a2.933 2.933 0 00-1.626-.49c-.605 0-1.163.18-1.628.49V13.633zM41.706 34.055l-1.626 2.81-10.515-6.051c.5-.251.936-.641 1.239-1.16a2.896 2.896 0 00.387-1.65l10.515 6.051zM16.423 36.865l-1.629-2.81 10.515-6.051a2.917 2.917 0 001.628 2.81l-10.514 6.05z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default ConsiderHabitsIcon;
