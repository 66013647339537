import React from "react";
import FaqItem from "../faqItem/FaqItem";

interface IFaq {
    question: string;
    answer: string;
}

const Faq = () => {
    const generalFAQ: IFaq[] = [
        {
            question: "What is an auto warranty?",
            answer: "An auto warranty is a contract that covers specific car repairs and issues for a certain period or mileage, offering financial protection against unexpected repair costs.",
        },
        {
            question: "What are the different types of auto warranties?",
            answer: "Common types include bumper-to-bumper, powertrain, corrosion, and roadside assistance warranties.",
        },
        {
            question: "How long do auto warranties typically last?",
            answer: "Depending on the manufacturer and warranty type, they generally last between 3 to 5 years or up to a specific mileage, such as 36,000 to 60,000 miles.",
        },
        {
            question: "What does a bumper-to-bumper warranty cover?",
            answer: "It covers almost all parts and systems of the vehicle except for wear-and-tear items like tires and brakes.",
        },
        {
            question: "What does a powertrain warranty include?",
            answer: "It covers essential components like the engine, transmission, and drivetrain.",
        },
        {
            question: "What is not covered under a standard auto warranty?",
            answer: "Standard warranties often exclude routine maintenance, wear-and-tear parts, and damage from accidents or misuse.",
        },
        {
            question: "How do I know if my vehicle is still under warranty?",
            answer: "Check the warranty booklet that came with your car, or contact the dealership or manufacturer with your VIN.",
        },
        {
            question: "Can I extend my auto warranty?",
            answer: "Yes, many manufacturers and third-party companies offer extended warranties for additional coverage.",
        },
        {
            question: "What is a certified pre-owned (CPO) warranty?",
            answer: "A CPO warranty is an additional warranty provided on used vehicles that meet specific age and mileage criteria set by the manufacturer.",
        },
        {
            question: "How do I file a claim with my auto warranty provider?",
            answer: "Contact your warranty provider or dealership, provide your vehicle details and the nature of the issue, and follow their instructions for repairs.",
        },
        {
            question: "Are warranties transferable if I sell my car?",
            answer: "Some warranties are transferable to a new owner, which can increase your car’s resale value.",
        },
        {
            question:
                "What should I look for when choosing an auto warranty provider?",
            answer: "Consider the provider’s reputation, coverage options, exclusions, claim process, and customer reviews.",
        },
        {
            question: "How do I avoid auto warranty scams?",
            answer: "Be wary of unsolicited offers, verify the provider’s credibility, and thoroughly read the terms and conditions.",
        },
        {
            question:
                "What is the difference between a warranty and car insurance?",
            answer: "A warranty covers mechanical failures and defects, while car insurance covers damage from accidents, theft, and natural disasters.",
        },
    ];
    const evFAQ: IFaq[] = [
        {
            question:
                "What specific warranties are available for electric vehicles (EVs)?",
            answer: "EVs typically come with battery warranties, powertrain warranties, and sometimes specific coverage for EV components.",
        },
        {
            question:
                "How long is the battery warranty for an electric vehicle?",
            answer: "Battery warranties often last eight years or 100,000 miles, whichever comes first.",
        },
        {
            question: "What does an EV battery warranty typically cover?",
            answer: "It covers defects in materials and workmanship, and often guarantees a certain percentage of battery capacity over the warranty period.",
        },
        {
            question: "Are charging components covered under an EV warranty?",
            answer: "Yes, many EV warranties cover parts like the charging port and onboard charger.",
        },
        {
            question:
                "What happens if my EV battery capacity drops below a certain percentage?",
            answer: "The warranty may cover battery repair or replacement if the capacity drops below a specified percentage (often around 70%).",
        },
        {
            question:
                "Are there specific maintenance requirements to keep an EV warranty valid?",
            answer: "Yes, following the manufacturer’s recommended maintenance schedule is crucial to keep the warranty valid.",
        },
        {
            question:
                "Does the warranty cover software updates and issues for EVs?",
            answer: "Some warranties cover software-related issues and updates, but they vary by manufacturer.",
        },
        {
            question:
                "How do EV warranties differ from traditional car warranties?",
            answer: "EV warranties often include specific coverage for the battery and electric drive components, which are not applicable to traditional cars.",
        },
        {
            question:
                "Are there any additional warranty considerations for hybrid vehicles?",
            answer: "Hybrid vehicles typically have warranties that cover both the internal combustion engine and the electric components.",
        },
        {
            question: "Can I get an extended warranty for my EV?",
            answer: "Yes, many manufacturers and third-party providers offer extended warranties for EVs.",
        },
    ];
    return (
        <section
            className="flex flex-col gap-4 lg:gap-12 items-center justify-center py-20"
            id="faq"
        >
            <h2 className="text-2xl lg:text-4xl font-bold max-w-3xl text-left sm:text-center">
                Everything You Need to Know About Auto Warranties: FAQs & Key
                Insights
            </h2>
            <p className="text-sm sm:text-base font-light">
                Auto warranties can be complex, but understanding them is
                crucial for making informed decisions about your vehicle’s
                maintenance and coverage. Here, we answer the most common
                questions about auto warranties, including those specific to
                electric vehicles (EVs).
            </p>
            <div className="w-full mt-8">
                <p className="text-2xl sm:text-4xl font-bold mb-4">
                    General Auto Warranty Questions
                </p>
                {generalFAQ.map((faq) => (
                    <FaqItem
                        key={faq.question}
                        question={faq.question}
                        answer={faq.answer}
                    />
                ))}
            </div>
            <div className="w-full mt-8">
                <p className="text-2xl sm:text-4xl font-bold mb-4">
                    EV Auto Warranty Questions
                </p>
                {evFAQ.map((faq) => (
                    <FaqItem
                        key={faq.question}
                        question={faq.question}
                        answer={faq.answer}
                    />
                ))}
            </div>
        </section>
    );
};

export default Faq;
