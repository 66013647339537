import React from "react";

function ResearchIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <rect
                width="55"
                height="55"
                x="0.5"
                y="0.5"
                fill="#fff"
                stroke="#537BD4"
                strokeDasharray="6 6"
                rx="4.5"
            ></rect>
            <path
                fill="#537BD4"
                d="M25.322 16c-5.134 0-9.323 4.189-9.323 9.323s4.188 9.322 9.322 9.322c5.134 0 9.323-4.188 9.323-9.322 0-5.134-4.188-9.323-9.322-9.323zm0 2.487a6.817 6.817 0 016.835 6.836 6.817 6.817 0 01-6.835 6.835 6.817 6.817 0 01-6.837-6.835 6.817 6.817 0 016.837-6.836z"
            ></path>
            <path
                fill="#537BD4"
                d="M31.538 30.268a1.243 1.243 0 00-.867 2.134l6.836 6.84a1.246 1.246 0 002.123-.882 1.244 1.244 0 00-.366-.88l-6.836-6.835a1.244 1.244 0 00-.89-.377z"
            ></path>
        </svg>
    );
}

export default ResearchIcon;
