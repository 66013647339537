import React from "react";
import styles from "./styles.module.scss";

interface IBgImageContentSection {
    id?: string;
    title: string;
    content: string;
    className: string;
    direction?: "left" | "right";
}

const BgImageContentSection = ({
    id = "",
    title,
    content,
    className,
    direction = "left",
}: IBgImageContentSection) => {
    return (
        <section className="relative" id={id}>
            <div
                className={`${styles["image-bg-section"]} ${
                    styles[`${className}`]
                }`}
            />
            <div className={`${styles["container"]} ${styles[`${direction}`]}`}>
                <h2 className="text-2xl sm:text-4xl text-white font-bold z-20 relative border-l-4 border-l-[#0094FF] pl-10 sm:max-w-lg">
                    {title}
                </h2>
                <br />
                <br />
                <p className="text-sm sm:text-base font-light text-white relative pl-0 sm:pl-10 max-w-xl">
                    {content}
                </p>
            </div>
        </section>
    );
};

export default BgImageContentSection;
