import React, { ReactElement } from "react";
import InfoCard from "./InfoCard";
import BlogCard from "./BlogCard";

export interface IImage {
    fullPath: string;
}
export interface ICardProps {
    type: "blog" | "info";
    icon?: ReactElement;
    title: string;
    text?: string;
    image?: IImage;
    url?: string;
}

const Card = ({ type, icon, title, text, image, url }: ICardProps) => {
    const cards = {
        info: <InfoCard icon={icon} title={title} text={text} />,
        blog: <BlogCard title={title} url={url} image={image} />,
    };

    return <div>{cards[type]}</div>;
};

export default Card;
