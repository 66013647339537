import { FilterQuestion } from "@lib/shared/types";
import { gettingYears } from "@lib/sharedUtils";

export const questions: { [x: string]: FilterQuestion[] } = {
    auto: [
        {
            label: "Zip Code",
            type: "text",
            value: "",
            name: "zipCode",
            isInHomePage: true,
            fieldType: "tel",
            placeholder: "Enter your zip code",
            mask: "zipCode",
            maxLength: 5,
        },
        {
            label: "Birth Year:",
            type: "select",
            options: gettingYears(1927, 2002).map((year) => ({
                label: `${year}`,
                value: `${year}`,
            })),
            value: "1985",
            name: "birthDate",
            isInHomePage: true,
        },
        {
            label: "Vehicles No.",
            type: "select",
            options: [
                { label: "1", value: "1" },
                { label: "2", value: "2" },
                { label: "3", value: "3" },
                { label: "4", value: "4" },
                { label: "5+", value: "5" },
            ],
            value: "1",
            name: "vehicleCount",
            isInHomePage: true,
        },
        {
            label: "Currently Insured? ",
            type: "toggle",
            value: "0",
            name: "currentlyInsured",
            isInHomePage: true,
        },

        {
            label: "Homeowner? ",
            type: "toggle",
            value: "0",
            name: "homeOwnership",
            isInHomePage: true,
        },
        {
            label: "Married?",
            type: "toggle",
            value: "0",
            name: "currentlyMarried",
            isInHomePage: false,
        },
    ],
    life: [
        {
            label: "Zip Code",
            type: "text",
            value: "",
            name: "zipCode",
            isInHomePage: true,
            fieldType: "tel",
            placeholder: "Enter your zip code",
            mask: "zipCode",
            maxLength: 5,
        },
        {
            label: "Birth Year:",
            type: "select",
            options: gettingYears(1927, 2002).map((year) => ({
                label: `${year}`,
                value: `${year}`,
            })),
            value: "1985",
            name: "birthDate",
            isInHomePage: true,
        },
        {
            label: "Gender:",
            type: "select",
            options: [
                { label: "Male", value: "M" },
                { label: "Female", value: "F" },
                { label: "Other", value: "X" },
            ],
            value: "",
            name: "gender",
            placeholder: "Select",
            isInHomePage: true,
        },

        {
            label: "Height:",
            type: "select",
            options: [
                { label: `5' 0"`, value: "60" },
                { label: `5' 1"`, value: "61" },
                { label: `5' 2"`, value: "62" },
                { label: `5' 3"`, value: "63" },
                { label: `5' 4"`, value: "64" },
                { label: `5' 5"`, value: "65" },
                { label: `5' 6"`, value: "66" },
                { label: `5' 7"`, value: "67" },
                { label: `5' 8"`, value: "68" },
                { label: `5' 9"`, value: "69" },
                { label: `5' 10"`, value: "70" },
                { label: `6' 0"`, value: "72" },
                { label: `6' 1"`, value: "73" },
                { label: `6' 2"`, value: "74" },
                { label: `6' 3"`, value: "75" },
                { label: `6' 4"`, value: "76" },
                { label: `6' 5"`, value: "77" },
                { label: `6' 6"`, value: "78" },
                { label: `6' 7"`, value: "79" },
                { label: `6' 8"`, value: "80" },
                { label: `6' 9"`, value: "81" },
                { label: `6' 10"`, value: "82" },
            ],
            value: "72",
            name: "height",
            isInHomePage: true,
        },

        {
            label: "Weight:",
            type: "select",
            options: new Array(36).fill(0).map((_, i) => ({
                value: `${100 + i * 10}`,
                label: `${100 + i * 10}`,
            })),
            value: "140",
            name: "weight",
            isInHomePage: true,
        },

        {
            label: "Coverage amount:",
            type: "select",
            options: [
                { label: "$50,000 or less", value: "50000" },
                { label: "$100,000", value: "100000" },
                { label: "$250,000", value: "250000" },
                { label: "$500,000", value: "500000" },
                { label: "$750,000", value: "750000" },
                { label: "$1,000,000", value: "1000000" },
                { label: "$1,500,000", value: "1500000" },
                { label: "$2,500,000+", value: "2500000" },
            ],
            value: "500000",
            name: "coverageAmount",
            isInHomePage: true,
        },

        {
            label: "Smoker?",
            type: "toggle",
            value: "0",
            name: "tobacco",
            isInHomePage: true,
        },
    ],
    health: [
        {
            label: "Zip Code",
            type: "text",
            value: "",
            name: "zipCode",
            isInHomePage: true,
            fieldType: "tel",
            placeholder: "Enter your zip code",
            mask: "zipCode",
            maxLength: 5,
        },
        {
            label: "Birth Year:",
            type: "select",
            options: gettingYears(1927, 2002).map((year) => ({
                label: `${year}`,
                value: `${year}`,
            })),
            value: "1985",
            name: "birthDate",
            isInHomePage: true,
        },
        {
            label: "Gender:",
            type: "select",
            options: [
                { label: "Male", value: "M" },
                { label: "Female", value: "F" },
                { label: "Other", value: "X" },
            ],
            value: "",
            name: "gender",
            placeholder: "Select",
            isInHomePage: true,
        },
        {
            label: "Household income?",
            type: "select",
            options: [
                { label: "$50,000 or less", value: "50000" },
                { label: "$100,000", value: "100000" },
                { label: "$250,000", value: "250000" },
                { label: "$500,000", value: "500000" },
                { label: "$750,000", value: "750000" },
                { label: "$1,000,000", value: "1000000" },
                { label: "$1,500,000", value: "1500000" },
                { label: "$2,500,000+", value: "2500000" },
            ],
            value: "500000",
            name: "householdIncome",
            isInHomePage: true,
        },
    ],
    medicare: [
        {
            label: "Zip Code",
            type: "text",
            value: "",
            name: "zipCode",
            isInHomePage: true,
            fieldType: "tel",
            placeholder: "Enter your zip code",
            mask: "zipCode",
            maxLength: 5,
        },
        {
            label: "Birth Year:",
            type: "select",
            options: gettingYears(1927, 2002).map((year) => ({
                label: `${year}`,
                value: `${year}`,
            })),
            value: "1957",
            name: "birthDate",
            isInHomePage: true,
        },
        {
            label: "Gender:",
            type: "select",
            options: [
                { label: "Male", value: "M" },
                { label: "Female", value: "F" },
                { label: "Other", value: "X" },
            ],
            value: "",
            name: "gender",
            placeholder: "Select",
            isInHomePage: true,
        },
        {
            label: "Credit Rating",
            type: "select",
            options: [
                { label: "Excellent", value: "excellent" },
                { label: "Good", value: "good" },
                { label: "Average", value: "average" },
                { label: "Below Average", value: "below average" },
                { label: "Poor", value: "poor" },
            ],
            value: "good",
            name: "creditRating",
        },
    ],
    "clinical-trials": [
        {
            label: "Zip Code",
            type: "text",
            value: "",
            name: "zipCode",
            isInHomePage: true,
            fieldType: "tel",
            placeholder: "Enter your zip code",
            mask: "zipCode",
            maxLength: 5,
        },
        // {
        //     label: "Age",
        //     type: "select",
        //     options: [
        //         { label: "18-49", value: "18-49" },
        //         { label: "18-70", value: "18-70" },
        //         { label: "18-75", value: "18-75" },
        //     ],
        //     value: "18-49",
        //     name: "age",
        // },
        // {
        //     label: "Gender",
        //     type: "select",
        //     options: [
        //         { label: "Female", value: "female" },
        //         { label: "Male", value: "male" },
        //         { label: "All", value: "all" },
        //     ],
        //     value: "all",
        //     name: "gender",
        // },
    ],
};
