import React from "react";
import CoverageTypes from "../coverageTypes/CoverageTypes";

const FifthSection = () => {
    return (
        <section className="flex flex-col justify-between items-center py-16">
            <div className="flex flex-col items-center gap-4">
                <h2 className="text-[#21409A] text-2xl sm:text-3xl max-w-xl text-left sm:text-center">
                    What's covered under an extended car warranty or VSC?
                </h2>
                <p className="text-sm sm:text-base text-left sm:text-center max-w-5xl">
                    Extended car warranties provide coverage for various parts
                    and components of your vehicle. Plans can differ from each
                    other in the extent to which they cover those important
                    parts. You can come across many types or tiers of plans,
                    some of which are:
                </p>
            </div>
            <CoverageTypes />
            <div>
                <p className="text-sm sm:text-base text-center max-w-5xl">
                    Some of the above parts categories might be called by
                    different names or divided into smaller component categories
                    depending on the provider, and the number of components
                    included will also differ. Most extended warranties will
                    also offer additional benefits, which may include roadside
                    assistance, towing, rental car or alternative transportation
                    reimbursements, and paying for your expenses if you break
                    down far away from home.
                </p>
                <br />
                <p className="text-base text-center max-w-5xl">
                    On the other hand, while regular vehicle maintenance is
                    crucial to maintaining your coverage, most warranty plans
                    will not cover it. You have to make the effort to keep up
                    with recommended service intervals, change fluids, replace
                    worn-out parts, and promptly address minor issues. Most
                    extended warranties require proof of proper maintenance to
                    honor claims.
                </p>
            </div>
        </section>
    );
};

export default FifthSection;
