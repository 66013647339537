import React from "react";

function CheckForFlexibilityIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <rect
                width="55"
                height="55"
                x="0.5"
                y="0.5"
                fill="#fff"
                stroke="#537BD4"
                strokeDasharray="6 6"
                rx="4.5"
            ></rect>
            <path
                fill="#537BD4"
                d="M23.597 18.634h2.742v6.761c.526.376 1.014.789 1.502 1.202.45-.413.977-.826 1.503-1.202v-6.76h2.742c.45 0 .863-.264 1.089-.677.188-.413.15-.901-.15-1.24l-4.245-5.295c-.45-.564-1.39-.564-1.84 0l-4.282 5.296c-.3.375-.338.863-.15 1.24.225.412.638.675 1.089.675zM18.376 25.883h-.714v-2.742c0-.45-.263-.864-.676-1.089-.413-.188-.901-.15-1.24.15l-5.295 4.207c-.263.225-.451.564-.451.94 0 .375.15.713.45.938l5.297 4.244c.225.188.488.263.75.263.189 0 .339-.037.527-.112.413-.188.676-.601.676-1.09v-2.741h.713c4.357 0 7.926 3.568 7.926 7.962v5.785c0 .826.676 1.502 1.502 1.502s1.503-.676 1.503-1.502v-5.785c0-6.047-4.92-10.93-10.968-10.93zM45.232 26.41l-5.296-4.208a1.162 1.162 0 00-1.24-.15c-.413.188-.676.601-.676 1.09v2.741h-.714c-2.93 0-5.558 1.127-7.512 2.968.601.863 1.09 1.765 1.503 2.741a7.88 7.88 0 016.01-2.78h.713v2.743c0 .45.263.864.676 1.09a1.2 1.2 0 00.526.112c.263 0 .526-.075.751-.263l5.259-4.207c.263-.225.45-.563.45-.939 0-.338-.15-.714-.45-.939z"
            ></path>
        </svg>
    );
}

export default CheckForFlexibilityIcon;
