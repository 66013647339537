export const resolveOperatingSystem = (
    userAgent: string | undefined,
): string | null => {
    let os = "Windows";
    if (userAgent) {
        if (userAgent.includes("Macintosh")) os = "Mac";
        if (userAgent.includes("iPad")) os = "iPad";
        if (userAgent.includes("Linux")) os = "Linux";
        if (userAgent.includes("iPhone")) os = "iPad";
        if (userAgent.includes("Android")) os = "Android";
        return os;
    }

    return null;
};
